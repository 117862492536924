import type { AppRouter } from 'testmetro-backend';

import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { createTRPCClient, httpBatchLink } from '@trpc/client';
import superJSON from 'superjson';

import { useAuthenticationTokens } from '../composables/use-authentication-tokens';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const { accessToken } = useAuthenticationTokens();
  const trpc = createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        headers() {
          if (!accessToken.value) return {};
          return {
            authorization: `Bearer ${accessToken.value}`,
          };
        },
        methodOverride: 'POST',
        transformer: superJSON,
        url: runtimeConfig.public.backendUrl,
      }),
    ],
  });
  return {
    provide: {
      trpc,
    },
  };
});
