import revive_payload_client_DeizBJIcCF from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_P6jv6ncSsX from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_G3kSafyAuo from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Mvsu9d6eX4 from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_afVnLlq17y from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RAEuzFx0kP from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hkNbzy05ay from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/testmetro/testmetro/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_h0ovcehthW from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.0.0__ioredis@5.4.1__dkxxkeni3dggomoolfvq5vkf2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8Fh11rGusX from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.21.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_tSL2EZZWuD from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.21.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_dkfe1VGVuB from "/home/runner/work/testmetro/testmetro/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_rollup@4.21.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import _01_trpc_client_JUYbTYjQ5G from "/home/runner/work/testmetro/testmetro/apps/frontend/app/plugins/01.trpc-client.ts";
export default [
  revive_payload_client_DeizBJIcCF,
  unhead_P6jv6ncSsX,
  router_G3kSafyAuo,
  payload_client_Mvsu9d6eX4,
  navigation_repaint_client_afVnLlq17y,
  check_outdated_build_client_RAEuzFx0kP,
  chunk_reload_client_hkNbzy05ay,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h0ovcehthW,
  switch_locale_path_ssr_8Fh11rGusX,
  i18n_tSL2EZZWuD,
  plugin_dkfe1VGVuB,
  _01_trpc_client_JUYbTYjQ5G
]