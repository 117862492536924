import { useCookie } from '#app';

export const useAuthenticationTokens = () => {
  const accessToken = useCookie('accessToken');
  const refreshToken = useCookie('refreshToken');

  const clearTokens = () => {
    accessToken.value = undefined;
    refreshToken.value = undefined;
  };

  return {
    accessToken,
    clearTokens,
    refreshToken,
  };
};
