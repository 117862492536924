<template>
  <NConfigProvider :theme="theme">
    <NNotificationProvider :max="3">
      <NDialogProvider>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </NDialogProvider>
    </NNotificationProvider>
  </NConfigProvider>
</template>

<script setup lang="ts">
import { NuxtLayout, NuxtPage } from '#components';
import { useDark } from '@vueuse/core';
import { darkTheme, NConfigProvider, NDialogProvider, NNotificationProvider } from 'naive-ui';
import { computed } from 'vue';
const isDark = useDark();
const theme = computed(() => (isDark.value ? darkTheme : undefined));
</script>
