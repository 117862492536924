import { default as indexioBCk7hMxRMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/admin/user-management/index.vue?macro=true";
import { default as indexSOeSIuS7rbMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/index.vue?macro=true";
import { default as create_45one5bn0bLbA5UMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/create-one.vue?macro=true";
import { default as custom_45due_45dateYIFNvTTmJaMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/custom-due-date.vue?macro=true";
import { default as indexXPgedVgUiRMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/index.vue?macro=true";
import { default as milestone_45listTXf31RlGDdMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/milestone-list.vue?macro=true";
import { default as create_45update_45oneEgNT4NORlcMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/create-update-one.vue?macro=true";
import { default as indexrVPHGtLNpoMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/index.vue?macro=true";
import { default as step8nHmRZRMD8Meta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/step.vue?macro=true";
import { default as create_45onekQUju8Q2WCMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/folder-list/create-one.vue?macro=true";
import { default as indexRaXMWBlgx9Meta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/folder-list/index.vue?macro=true";
import { default as index0Q507sjP7pMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/index.vue?macro=true";
import { default as create_45oneCXuPlP0nyfMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/create-one.vue?macro=true";
import { default as indexbb1tbUNDuxMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/index.vue?macro=true";
import { default as _91token_93dVgghwl7TeMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/[token].vue?macro=true";
import { default as expiredSkHYIvagN1Meta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/expired.vue?macro=true";
import { default as indexBNTqEW3AL7Meta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/index.vue?macro=true";
import { default as successhWZWjTnKxnMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/success.vue?macro=true";
import { default as signin2i3fPNwUWGMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signin.vue?macro=true";
import { default as indexTqR4O5PiREMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signup/index.vue?macro=true";
import { default as successfYw4KKG1rmMeta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signup/success.vue?macro=true";
import { default as _91token_93pAXPb4wgb7Meta } from "/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/verify/[token].vue?macro=true";
export default [
  {
    name: "admin-user-management",
    path: "/admin/user-management",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/admin/user-management/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexSOeSIuS7rbMeta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/index.vue")
  },
  {
    name: "project-id-milestone-create-one",
    path: "/project/:id()/milestone/create-one",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/create-one.vue")
  },
  {
    name: "project-id-milestone-custom-due-date",
    path: "/project/:id()/milestone/custom-due-date",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/custom-due-date.vue")
  },
  {
    name: "project-id-milestone",
    path: "/project/:id()/milestone",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/index.vue")
  },
  {
    name: "project-id-milestone-milestone-list",
    path: "/project/:id()/milestone/milestone-list",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/milestone/milestone-list.vue")
  },
  {
    name: "project-id-repository-case-list-create-update-one",
    path: "/project/:id()/repository/case-list/create-update-one",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/create-update-one.vue")
  },
  {
    name: "project-id-repository-case-list",
    path: "/project/:id()/repository/case-list",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/index.vue")
  },
  {
    name: "project-id-repository-case-list-step",
    path: "/project/:id()/repository/case-list/step",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/case-list/step.vue")
  },
  {
    name: "project-id-repository-folder-list-create-one",
    path: "/project/:id()/repository/folder-list/create-one",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/folder-list/create-one.vue")
  },
  {
    name: "project-id-repository-folder-list",
    path: "/project/:id()/repository/folder-list",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/folder-list/index.vue")
  },
  {
    name: "project-id-repository",
    path: "/project/:id()/repository",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/[id]/repository/index.vue")
  },
  {
    name: "project-create-one",
    path: "/project/create-one",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/create-one.vue")
  },
  {
    name: "project",
    path: "/project",
    meta: indexbb1tbUNDuxMeta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/project/index.vue")
  },
  {
    name: "user-reset-password-token",
    path: "/user/reset-password/:token()",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/[token].vue")
  },
  {
    name: "user-reset-password-expired",
    path: "/user/reset-password/expired",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/expired.vue")
  },
  {
    name: "user-reset-password",
    path: "/user/reset-password",
    meta: indexBNTqEW3AL7Meta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/index.vue")
  },
  {
    name: "user-reset-password-success",
    path: "/user/reset-password/success",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/reset-password/success.vue")
  },
  {
    name: "user-signin",
    path: "/user/signin",
    meta: signin2i3fPNwUWGMeta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signin.vue")
  },
  {
    name: "user-signup",
    path: "/user/signup",
    meta: indexTqR4O5PiREMeta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signup/index.vue")
  },
  {
    name: "user-signup-success",
    path: "/user/signup/success",
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/signup/success.vue")
  },
  {
    name: "user-verify-token",
    path: "/user/verify/:token()",
    meta: _91token_93pAXPb4wgb7Meta || {},
    component: () => import("/home/runner/work/testmetro/testmetro/apps/frontend/app/pages/user/verify/[token].vue")
  }
]